<template>
  <nav
    v-click-outside="closeMenu"
    class="base-navbar"
  >
    <div class="container">
      <div
        class="burger-menu d-flex d-lg-none"
        @click="menu = !menu"
      >
        <span />
        <span />
        <span />
      </div>

      <router-link
        :to="{ name: 'cart' }"
        class="text-decoration-none cart-icon cursor-pointer cart-small-screen"
      >
        <CartIcon class="main-color-fill-svg" />
        <p class="cart-quantity">
          {{ cartQuantity }}
        </p>
      </router-link>

      <!-- Lang Switcher -->
      <LangSwitcher />
      <!-- End OF lang -->

      <div class="row align-items-center">
        <div class="col-xl-2 col-lg-2">
          <div class="logo">
            <!-- <router-link :to="`/${$i18n.locale}/`"> -->
            <img
              src="@/assets/images/shared/waffarha-logo.png"
              width="130"
              alt=""
            >
            <!-- </router-link> -->
          </div>
        </div>

        <div class="col-xl-6 col-lg-6">
          <div class="main-menu d-none d-lg-block">
            <ul
              class="list-unstyled d-flex justify-content-start align-items-center gap-16"
            >
              <router-link
                v-for="(link, i) in navbarLinks"
                :key="`navbar-links-${i}`"
                tag="li"
                :to="link.to"
                exact
                active-class="active-menu"
              >
                <a
                  class="d-flex justify-content-center align-items-center gap-10"
                >
                  <div
                    class="icon radius-full light-gray-color-bg-with-opacity"
                  >
                    <component
                      :is="link.icon"
                      class="light-gray-color-fill-svg"
                    />
                  </div>
                  <span class="font-weight-600 fs-16 light-gray-color-text">
                    {{ $t(link.title) }}
                  </span>
                </a>
              </router-link>
            </ul>
          </div>
        </div>

        <div class="col-xl-4 col-lg-4 d-none d-lg-block">
          <div
            class="user-links d-flex align-items-end justify-content-center gap-10"
          >
            <!-- Lang Switcher -->
            <!-- <LangSwitcher/> -->
            <!-- End OF lang -->

            <router-link
              :to="{ name: 'cart' }"
              class="text-decoration-none cart-icon main-icon main-color-bg-with-opacity radius-full cursor-pointer"
            >
              <CartIcon class="main-color-fill-svg" />
              <p class="cart-quantity">
                {{ cartQuantity }}
              </p>
            </router-link>

            <div
              v-if="isLoggedIn"
              v-click-outside="closeDropdown"
              class="user-dropdown"
            >
              <div
                class="user-box cursor-pointer d-flex justify-content-between align-items-center gap-20"
                @click="showDropdown = !showDropdown"
              >
                <img
                  :src="userData.users_picture"
                  alt=""
                  class="radius-full"
                  width="40"
                  height="40"
                >

                <div
                  class="d-flex justify-content-start align-items-center gap-2"
                >
                  <span class="medium-font">
                    {{ $t('home.title') }}
                    {{ userData.users_fname }}
                  </span>
                  <ArrowDown />
                </div>
              </div>

              <transition name="fadeUp">
                <ul
                  v-if="showDropdown"
                  class="content-dropdown p-y-12 radius-6"
                >
                  <li
                    v-for="(link, i) in dropdownLinks"
                    :key="`dropdown-links-${i}`"
                    @click="handelAction(link)"
                  >
                    <router-link
                      :to="link.to"
                      class="p-y-10 p-x-14 d-flex justify-content-start align-items-center gap-10"
                    >
                      <div class="icon radius-full">
                        <component
                          :is="link.icon"
                          class="light-gray-color-fill-svg"
                        />
                      </div>
                      <span class="medium-font fs-14 light-gray-color-text">
                        {{ $t(link.title) }}</span>
                    </router-link>
                  </li>
                </ul>
              </transition>
            </div>

            <div v-else>
              <div
                class="user-box cursor-pointer d-flex justify-content-between align-items-center gap-20"
              >
                <router-link
                  :to="{ name: 'enter-phone-number' }"
                  class="text-decoration-none cart-icon main-color-bg-with-opacity radius-full cursor-pointer"
                >
                  <LoginIcon class="main-color-fill-svg" />
                </router-link>

                <div
                  class="d-flex justify-content-start align-items-center gap-2"
                >
                  <router-link
                    :to="{ name: 'enter-phone-number' }"
                    class="medium-font black-color-text text-decoration-none"
                  >
                    <span class="medium-font">{{ $t('home.myAccount') }}</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition name="fadeUp">
      <div
        v-if="menu"
        class="mobile-menu d-block d-lg-none p-y-10"
      >
        <div class="container">
          <ul class="list-unstyled">
            <li
              v-for="(link, i) in smallNavLinks"
              :key="`small-nav-links-${i}`"
              @click="handelAction(link)"
            >
              <router-link
                :to="link.to"
                class="p-y-10 p-x-14 d-flex justify-content-start align-items-center gap-10"
                @click="closeMenu"
              >
                <div class="icon radius-full">
                  <component
                    :is="link.icon"
                    class="light-gray-color-fill-svg"
                  />
                </div>
                <span class="medium-font fs-14 light-gray-color-text">{{
                  $t(link.title)
                }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </nav>
</template>

<script>
import LoginIcon from '../../icons/LoginIcon'
import { navbar, dropdown } from './data'
import PercentageIcon from '../../icons/PercentageIcon'
import LangSwitcher from '../base-lang-switcher/index.vue'

export default {
  name: 'BaseNavbar',
  components: {
    PercentageIcon,
    LoginIcon,
    LangSwitcher
  },
  data () {
    return {
      menu: false,
      showDropdown: false
    }
  },

  computed: {
    cartQuantity () {
      return this.$store.getters['cart/CartQuantity']
    },

    userData () {
      return this.$store.getters['Globals/userInfo']
    },

    isLoggedIn () {
      return this.$store.getters['Globals/checkUserLogin']
    },

    navbarLinks () {
      return navbar
    },

    isLoggedIn () {
      return this.$store.getters['Globals/checkUserLogin']
    },
    navbarLinks () {
      return navbar
    },

    dropdownLinks () {
      let existingNav = []
      if (this.isLoggedIn) {
        existingNav = [...dropdown.filter((el) => el.requireAuth)]
      } else {
        existingNav = [...dropdown.filter((el) => !el.requireAuth)]
      }
      return existingNav
      // return dropdown
    },

    smallNavLinks () {
      let existingNav = [...navbar]
      if (this.isLoggedIn) {
        existingNav = [
          ...existingNav,
          ...dropdown.filter((el) => el.requireAuth)
        ]
      } else {
        existingNav = [
          ...existingNav,
          ...dropdown.filter((el) => !el.requireAuth)
        ]
      }
      return existingNav
    }
  },

  created () {
    this.getCartQuantity()
  },

  methods: {
    handelAction (link) {
      this.showDropdown = false
      if (!link.isRouteAction) {
        this[link.actionId]()
      }
    },

    logout () {
      this.$store.dispatch('Globals/logout')
    },

    closeMenu () {
      this.menu = false
    },

    closeDropdown () {
      this.showDropdown = false
    },

    getCartQuantity () {
      this.$store.dispatch('cart/getGeneralData')
    }
  }
}
</script>

<style lang="scss" scoped>
@import './base-navbar';
</style>

export const navbar = [
  {
    to: { name: 'Home' },
    title: 'home.home',
    isRouteAction: true,
    icon: 'HomeIcon'
  },

  {
    to: { name: 'new-offers' },
    title: 'home.offers',
    isRouteAction: true,
    icon: 'PercentageIcon'
  }
]
export const dropdown = [
  // {
  //   to: { name: 'my-account' },
  //   title: 'home.myAccount',
  //   requireAuth: true,
  //   isRouteAction: true,
  //   icon: 'UserIcon'
  // },

  {
    to: { name: 'my-orders' },
    title: 'home.myOrders',
    requireAuth: true,
    isRouteAction: true,
    icon: 'OrdersIcon'
  },

  {
    to: { name: 'favorite-offers' },
    title: 'home.savedOffers',
    requireAuth: true,
    isRouteAction: true,
    icon: 'HeartLineIcon'
  },

  {
    to: { name: 'settings' },
    title: 'home.settings',
    requireAuth: true,
    isRouteAction: true,
    icon: 'settingsIcon'
  },

  // {
  //   to: { name: 'invite-your-firend' },
  //   title: 'home.invite',
  //   requireAuth: true,
  //   isRouteAction: true,
  //   icon: 'ShareIcon'
  // },

  {
    to: { name: 'Home' },
    title: 'home.singOut',
    actionId: 'logout',
    requireAuth: true,
    icon: 'LogoutIcon'
  },

  {
    to: { name: 'enter-phone-number' },
    title: 'home.signIn',
    requireAuth: false,
    isRouteAction: true,
    icon: 'UserIcon'
  },

  {
    to: { name: 'enter-phone-number' },
    title: 'home.newAccount',
    requireAuth: false,
    isRouteAction: true,
    icon: 'UserIcon'
  }
]
// export const smallNav = [...navbar, ...dropdown]

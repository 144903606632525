<template>
  <div :class="isLoggedIn ? 'isLoggedInLang' : 'notLoggedInLang'">
    <div class="lang-block">
      <div
        v-if="$i18n.locale === 'en'"
        class="lang"
        @click="onSetLocale('ar')"
      >
        العربية
      </div>

      <div
        v-if="$i18n.locale === 'ar'"
        class="lang"
        @click="onSetLocale('en')"
      >
        English
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '../../../lang'

export default {
  name: 'LangSwitcher',

  computed: {
    isLoggedIn () {
      return this.$store.getters['Globals/checkUserLogin']
    }
  },

  methods: {
    onSetLocale (locale) {
      this.$i18n.locale = locale
      localStorage.setItem('lang', locale)
      this.$router.push({
        params: { lang: locale }
      })
      console.log('i18n', this.$i18n.locale)
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
@import './base-lang.scss';
</style>
